import { template as template_e96bc20110634db7a21d847ab39b9a8f } from "@ember/template-compiler";
import FKText from "discourse/form-kit/components/fk/text";
const FKFieldset = template_e96bc20110634db7a21d847ab39b9a8f(`
  <fieldset name={{@name}} class="form-kit__fieldset" ...attributes>
    {{#if @title}}
      <legend class="form-kit__fieldset-title">{{@title}}</legend>
    {{/if}}

    {{#if @description}}
      <FKText class="form-kit__fieldset-description">
        {{@description}}
      </FKText>
    {{/if}}

    {{yield}}
  </fieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKFieldset;
